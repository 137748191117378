import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.object.keys";
import "core-js/modules/es6.function.name";
import _slicedToArray from "/var/www/erp/erpadmin/node_modules/@babel/runtime-corejs2/helpers/esm/slicedToArray.js";
import "core-js/modules/es6.array.from";
import _defineProperty from "/var/www/erp/erpadmin/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.map";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { array2options, throwerror } from '@/core/provide';
import { usersdata } from '@/api/user';
import { platformplatform, allCountryList, currencycurrency, accountaccount, searchProprietaryWebsite, accountlist } from '@/api/common';
import { LogisticsTenantChannel, LogisticsTenantChannelWithDisable, feefee } from '@/api/lms';
import { stocklist, getAllReservoir, samplingRules, stockEntityAll } from '@/api/stock';
import { brandsearch, lowestCategories, noTemplateCategories, sizeGuideHeadAllActive } from '@/api/product';
import { availablesupplierlist } from "@/api/pms";
/**
 * TODO 组件需要 显示的是label  绑定组件的值是value
 * @param {String} value - 服务器返回的那个属性  要提交服务器的  对应给组件的value的key
 * @param {String} label - 服务器返回的那个属性  要提交服务器的  对应给组件的label的key
 * @param {Function} api - 请求接口的api
 * @param {String} keyword - 输入查找时候服务器需要这个接口请求对应的属性 如果是不需要输入下拉的话那就传空字符串
 * @param {String} name - 提示哪个接口
 */

var createItem = function createItem(value, label, api, keyword, name) {
  return {
    keyword: keyword,
    //请求name的参数属性  一般是文本输入
    transfor: {
      value: value,
      label: label
    },
    api: api,
    name: name
  };
};

var apiMap = new Map([//用户接口
['user', createItem('id', 'user_name', usersdata, 'user_name', '用户接口')], //用户接口
['username', createItem('user_name', 'user_name', usersdata, 'user_name', '用户接口')], //平台接口
['platform', createItem('platform_code', 'platform', platformplatform, '', '平台接口')], //所有国家
['country', createItem('country_code', 'country_zh', allCountryList, '', '所有国家')], //所有国家  英文
['country_en', createItem('country_code', 'country_en', allCountryList, '', '所有国家')], //所有币种
['currency', createItem('currency_code', 'currency', currencycurrency, '', '所有币种')], //仓库
['stock', createItem('stock_code', 'stock_name', stocklist, '', '所有仓库')], //仓库主体
['stockEntity', createItem('entity_code', 'entity_name', stockEntityAll, '', '仓库主体')], //所有店铺
['account', createItem('account_code', 'account_name', accountaccount, '', '所有店铺')], //物流渠道
['logistics', createItem('logistics_channel_code', 'name_cn', LogisticsTenantChannel, 'name', '物流渠道')], //物流渠道含禁用
['logisticsWithDisable', createItem('logistics_channel_code', 'name_cn', LogisticsTenantChannelWithDisable, 'name', '物流渠道（含禁用）')], //自营平台站点
['selfsite', createItem('website_code', 'website_name', searchProprietaryWebsite, '', '物流渠道')], //费用类型
['feeType', createItem('fee_type_code', 'fee_type_name', feefee, '', '费用类型')], //当前租户所有库区
['reservoir', createItem('reservoir_code', 'reservoir_name', getAllReservoir, '', '当前租户所有库区')], // 销售账号
['accountListName', createItem('account_code', 'account_name', accountlist, '', '销售账号')], ['noTemplateLowestCategories', createItem('category_code', 'category_name', noTemplateCategories, '', '没有绑定尺寸模板的最底级分类')], ['lowestCategories', createItem('category_code', 'category_name', lowestCategories, '', '最底级分类')], ['sizeGuideHeadAllActive', createItem('head_name', 'head_name', sizeGuideHeadAllActive, '', '启用的表头')], ['brand', createItem('brand_code', 'name_zh', brandsearch, '', '品牌')], ['supplierAvailable', createItem('supplier_no', 'supplier_full_name', availablesupplierlist, '', '供应商')], ['samplingRules', createItem('id', 'title', samplingRules, '', '抽检规则')]]);
export default function queryList(apiname, keyword, defaultProps) {
  if (apiMap.has(apiname)) {
    var map = apiMap.get(apiname);
    return map.api(_objectSpread({}, map.keyword && keyword ? _defineProperty({}, map.keyword, keyword) : {})).then(function (_ref2) {
      var code = _ref2.code,
          data = _ref2.data;

      if (code === SUCCESSCODE) {
        var compatibleDataList = data.constructor === Object ? data.list : data;
        return array2options(compatibleDataList || [], defaultProps || map.transfor);
      }

      return [];
    });
  } else {
    return Promise.reject(new Error("\u641C\u7D22\u8F93\u5165\u6846\u4E2D\u627E\u4E0D\u5230\u5F53\u524D\u8FD9\u4E2A".concat(apiname, "\u7684\u914D\u7F6E")));
  }
}
var errorTexts = Array.from(apiMap.entries()).map(function (_ref3) {
  var _ref4 = _slicedToArray(_ref3, 2),
      key = _ref4[0],
      name = _ref4[1].name;

  return "".concat(key, "-----").concat(name);
}).join('\n');
export var checkApiName = function checkApiName(apiname, initOptions) {
  if (!apiname) {
    return throwerror("\n            \u8BF7\u6DFB\u52A0\u5C5E\u6027apiName,  \u5F53\u524D\u7EC4\u4EF6\u53EA\u4E3A\u4E86\u4E0B\u62C9\u641C\u7D22\u5199\u7684\n".concat(errorTexts, "\n        "));
  }

  var item = apiMap.get(apiname);

  if (!item) {
    return throwerror("\u5F53\u524D\u7EC4\u4EF6\u627E\u4E0D\u5230\u6240\u914D\u7F6E\u7684apiname, \n".concat(errorTexts));
  }

  if (!item.keyword && initOptions) {
    return throwerror("\u4E0D\u662F\u641C\u7D22\u4E0B\u62C9\u8BF7\u6C42\u63A5\u53E3\u8BF7\u4E0D\u8981\u4F20initOptions");
  }
}; //如果 keyword 有的话  那么就是搜索下拉的  如果没有就不是搜索下拉的

export var isSingle = function isSingle(name) {
  return apiMap.has(name) && !apiMap.get(name).keyword;
};