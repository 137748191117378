import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/www/erp/erpadmin/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { get, post, put, del, productConfig, productListingConfig, productListingHkConfig, pmsConfig, omsConfig, uploadNew } from '@/api';
/**
 * TODO 这是关于商品的api
 */

import { cacheApi, oneCacheApi } from '@/core/provide'; //品牌列表

export var brandindex = function brandindex(params) {
  return get('/brand/index', params, productConfig());
}; //创建品牌

export var brandcreate = function brandcreate(params) {
  return post('/brand/createBrand', params, productConfig());
}; //品牌更新

export var brandupdate = function brandupdate(params) {
  return post('/brand/updateBrand', params, productConfig());
}; //更新品牌状态

export var brandupdatestatus = function brandupdatestatus(params) {
  return post('/brand/updateBrandStatus', params, productConfig());
}; //品牌下拉列表

export var brandselect = function brandselect(params) {
  return get('/brand/select', params, productConfig());
};
/**
 * @deprecated 旧接口，废弃了
 * 规格列表
 */

export var specindex = function specindex(params) {
  return get('/customQuickAttr/index', params, productConfig());
};
/**
 * @deprecated 旧接口，废弃了
 * 规格创建
 */

export var speccreate = function speccreate(params) {
  return post('/customQuickAttr/batchCreateCustomQuickAttr', params, productConfig());
};
/**
 * @deprecated 旧接口，废弃了
 * 规格更新
 */

export var specupdate = function specupdate(params) {
  return post('/customQuickAttr/updateCustomQuickAttr', params, productConfig());
}; // 规格列表（新版-2021-10-20）

export var specnewindex = function specnewindex(params) {
  return get('/attributes', params, productConfig());
}; // 规格创建（新版-2021-10-20）

export var specnewcreate = function specnewcreate(params) {
  return post('/attributes', params, productConfig());
}; // 规格更新（新版-2021-10-20）

export var specnewupdate = function specnewupdate(params) {
  return put("/attributes/".concat(params.id), params, productConfig());
}; // 规格删除（新版-2021-10-20）

export var specnewdelete = function specnewdelete(params) {
  return del("/attributes/".concat(params.id), params, productConfig());
}; //规格值新增和编辑（新版-2021-10-20）

export var specnewvaluesave = function specnewvaluesave(params) {
  return post('/attributesValue', params, productConfig());
}; //根据规格ID获取分组

export var specGetAttributesGroup = function specGetAttributesGroup(params) {
  return get('/attributesValueGroup', params, productConfig());
}; //更新分组数据

export var specAttributesGroupUpdata = function specAttributesGroupUpdata(params) {
  return post('/attributesValueGroup', params, productConfig());
}; // 获取指定分组属性

export var attributesGroupWithValue = function attributesGroupWithValue(params) {
  return get('/attribute/group-with-value', params, productConfig());
}; // 获取颜色

export var attributescolors = function attributescolors(params) {
  return get('/attribute/colors', params, productConfig());
}; //品牌搜索下拉

export var brandsearch = function brandsearch(params) {
  return get('/brand/search', _objectSpread({
    page_size: 100,
    page: 1
  }, params), productConfig({
    loading: false
  }));
};
export var userBrand = function userBrand(params) {
  return get('/brand/user-brand', params, productConfig());
}; //分类树

export var categorytree = function categorytree(params) {
  return get('/category/tree', params, productConfig());
}; // 分类下拉

export var categoryselect = function categoryselect(params) {
  return get('/category/select', params, productConfig());
}; // 分类树，带属性更新时间、更人人

export var categorynewattributetree = function categorynewattributetree(params) {
  return get('/category/new-attribute-category-tree', params, productConfig());
};
/**
 * @deprecated 旧接口，废弃了
 * 获取分类下自定义快捷规格列表
 */

export var speccategory = function speccategory(params) {
  return get('/customQuickAttr/categorycustomQuickAttr', params, productConfig());
}; //更新规格状态

export var specupdatestatus = function specupdatestatus(params) {
  return post('/customQuickAttr/updateCustomQuickAttrStatus', params, productConfig());
}; //分类创建

export var categorycreate = function categorycreate(params) {
  return post('/category/createCategory', params, productConfig());
}; //分类更新

export var categoryupdate = function categoryupdate(params) {
  return post('/category/updateCategory', params, productConfig());
}; //更新分类状态

export var categoryupdatestatus = function categoryupdatestatus(params) {
  return post('/category/updateCategoryStatus', params, productConfig());
};
export var lowestCategories = function lowestCategories(params) {
  return get('/category/lowest', params, productConfig());
}; // 面料列表

export var materiallist = function materiallist(params) {
  return get('/materials', params, productConfig());
}; // 证书标签列表

export var certificatelist = function certificatelist(params) {
  return get('/certificate', params, productConfig());
}; //性质类型列表

export var natureTypeindex = cacheApi(function (params) {
  return get('/NatureType', params, productConfig());
}); //创建商品

export var categorycreateProduct = function categorycreateProduct(params) {
  return post('/product/createProduct', params, productConfig());
}; //更新商品

export var categoryupdateProduct = function categoryupdateProduct(params) {
  return post('/product/updateProduct', params, productConfig());
}; //更新商品生产资料

export var productioninfoupdate = function productioninfoupdate(params) {
  return post('/product/production-info/update', params, productConfig());
}; //更新商品

export var productdetail = function productdetail(params) {
  return get('/product/detail', params, productConfig());
}; //商品列表

export var getProductList = function getProductList(params) {
  return get('/product/index', params, productConfig());
}; //试衣资料静态属性

export var productFittingStatic = function productFittingStatic(params) {
  return get('/product/fitting-static', params, productConfig());
}; // 校验商品绑定属性组

export var productchecknewattributevalues = function productchecknewattributevalues(params) {
  return post('/product/check-new-attribute-values', params, productConfig());
}; //复制商品

export var productcopy = function productcopy(params) {
  return post('/product/copy', params, productConfig());
}; //模特列表

export var modellibraryindex = function modellibraryindex(params) {
  return get('/model-library/index', params, productConfig());
}; //模特静态属性

export var modellibraryallstatic = function modellibraryallstatic(params) {
  return get('/model-library/all-static', params, productConfig());
}; //模特下拉列表

export var modellibraryselectlist = function modellibraryselectlist(params) {
  return get('/model-library/select-list', params, productConfig());
}; //模特详情

export var modellibrarydetail = function modellibrarydetail(params) {
  return get('/model-library/detail', params, productConfig());
}; //创建模特

export var modellibrarycreate = function modellibrarycreate(params) {
  return post('/model-library/create-model-library', params, productConfig());
}; //更新模特

export var modellibraryupdate = function modellibraryupdate(params) {
  return post('/model-library/update-model-library', params, productConfig());
}; //删除模特

export var modellibrarydelete = function modellibrarydelete(params) {
  return get('/model-library/delete-model-library', params, productConfig());
}; //试衣模特列表

export var modelfittingindex = function modelfittingindex(params) {
  return get('/model-fitting/index', params, productConfig());
}; //试衣模特静态属性

export var modelfittingallstatic = function modelfittingallstatic(params) {
  return get('/model-fitting/all-static', params, productConfig());
}; //试衣模特下拉列表

export var modelfittingselectlist = function modelfittingselectlist(params) {
  return get('/model-fitting/select-list', params, productConfig());
}; //试衣模特详情

export var modelfittingdetail = function modelfittingdetail(params) {
  return get('/model-fitting/detail', params, productConfig());
}; //创建试衣模特

export var modelfittingcreate = function modelfittingcreate(params) {
  return post('/model-fitting/create', params, productConfig());
}; //更新试衣模特

export var modelfittingupdate = function modelfittingupdate(params) {
  return post('/model-fitting/update', params, productConfig());
}; //删除试衣模特

export var modelfittingdelete = function modelfittingdelete(params) {
  return get('/model-fitting/delete', params, productConfig());
}; //批量导出商品数据

export var productExpGoods = function productExpGoods(params) {
  return post('/product/expGoods', params, productConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; // 批量修改商品分类

export var batchUpdateProductCategory = function batchUpdateProductCategory(params) {
  return post('/product/batchUpdateProductCategory', params, productConfig());
}; // 批量修改商品品牌

export var batchUpdateProductBrand = function batchUpdateProductBrand(params) {
  return post('/product/batchUpdateProductBrand', params, productConfig());
}; // 批量删除商品

export var batchDeleteProduct = function batchDeleteProduct(params) {
  return post('/product/batchDeleteProduct', params, productConfig());
}; // 商品刊登同步

export var createListingProduct = function createListingProduct(params) {
  return post('/listing/createListingProduct', params, productListingConfig());
}; // 根据平台编码获取分类树

export var getListingCategoryTree = function getListingCategoryTree(params) {
  return get('/listingCategory/tree', params, productListingConfig());
}; // 保存站点分类映射数据

export var saveSiteCategoryMappingTree = function saveSiteCategoryMappingTree(params) {
  return post('/listingCategory/updateBindingProprietaryCategory', params, productListingConfig());
}; // 待刊登nadiera平台商品列表

export var getBeListingList = function getBeListingList(params) {
  return get('/proprietaryProduct/beListingList', params, productListingConfig());
}; // 刊登nadiera平台商品列表

export var getListingLogList = function getListingLogList(params) {
  return get('/proprietaryProduct/listingLogList', params, productListingConfig());
}; // 商品导入结果

export var getImportProductTaskList = function getImportProductTaskList(params) {
  return get('/product/importProductTaskList', params, productConfig());
}; // 导入商品

export var createImportProductTask = function createImportProductTask(params) {
  return post('/product/createImportProductTask', params, productConfig());
}; // 取消导入

export var cancelImportProductTask = function cancelImportProductTask(params) {
  return post('/product/cancelImportProductTask', params, productConfig());
}; // 根据SKU获取商品信息

export var detailByGoodsSn = function detailByGoodsSn(params) {
  return get('/product/detailByGoodsSn', params, productConfig());
}; //查询产品销量

export var getProductSales = function getProductSales(params) {
  return get('/shopify/product/analyze/list', params, productListingConfig());
}; //新品跟进表导出

export var exportProductSales = function exportProductSales(params) {
  return get('/shopify/product/analyze/export', params, productListingConfig());
}; //异步导入导出列表

export var asyncExportOrImportList = function asyncExportOrImportList(params) {
  return get('/common/export-or-import', params, productListingConfig());
}; //修改产品备注

export var setRemark = function setRemark(params) {
  return post('/shopify/product/analyze/remark', params, productListingConfig());
}; //机器人
//1688爬虫商品列表

export var reptile1688AliProductindex = function reptile1688AliProductindex(params) {
  return get('/reptileAliProduct/list', params, productConfig());
}; //批量同步1688到商品

export var reptile1688AliProductbatchSyncProduct = function reptile1688AliProductbatchSyncProduct(params) {
  return post('/reptileAliProduct/batchSyncProduct', params, productConfig());
}; //重新刊登nadiera平台

export var resetListingProduct = function resetListingProduct(params) {
  return post('/proprietaryProduct/resetListingProduct', params, productListingConfig());
}; //产品列表

export var productgoodsList = function productgoodsList(params) {
  return post('/product/goodsList', params, productConfig());
}; //批量修改商品标题

export var batchUpdateProductTitle = function batchUpdateProductTitle(params) {
  return post('/product/batchUpdateProductTitle', params, productConfig());
};
export var batchproductDetailByGoodsSn = function batchproductDetailByGoodsSn(params) {
  return get('/product/productDetailByGoodsSn', params, productConfig());
};
export var batchproductDetailByGoodsSnPost = function batchproductDetailByGoodsSnPost(params) {
  return post('/product/productDetailByGoodsSn', params, productConfig());
};
export var batchChangeProductSaleStatus = function batchChangeProductSaleStatus(params) {
  return post('/product/sale-status', params, productConfig());
};
export var batchChangeProductPreOrder = function batchChangeProductPreOrder(params) {
  return post('/product/pre-order', params, productConfig());
};
export var getProductVariationLog = function getProductVariationLog(params) {
  return get('/product/variation-log', params, productConfig());
};
export var productgoodsaccessorieslist = function productgoodsaccessorieslist(params) {
  return post('/product/goods-accessories-list', params, productConfig());
}; //面辅料产品列表
//创建自定义描述属性

export var batchCreateCustomDescAttr = function batchCreateCustomDescAttr(params) {
  return post('/customQuickDescAttr/batchCreateCustomQuickDescAttr', params, productConfig());
}; //自定义快捷描述属性列表

export var customQuickDescAttrindex = function customQuickDescAttrindex(params) {
  return get('/customQuickDescAttr/index', params, productConfig());
}; // 二级分类属性、属性值列表

export var getattributebycategorycode = function getattributebycategorycode(params) {
  return get('/category/get-attribute-by-category-code', params, productConfig());
}; //更新自定义描述属性

export var updateCustomQuickDescAttr = function updateCustomQuickDescAttr(params) {
  return post('/customQuickDescAttr/updateCustomQuickDescAttr', params, productConfig());
}; //更新自定义快捷描述属性状态

export var updateCustomQuickDescAttrStatus = function updateCustomQuickDescAttrStatus(params) {
  return post('/customQuickDescAttr/updateCustomQuickDescAttrStatus', params, productConfig());
}; //复制自定义快捷描述属性

export var copycustomquickdescattr = function copycustomquickdescattr(params) {
  return post('/custom-quick-desc-attr/copy', params, productConfig());
}; // 创建一级分类属性（一级分类、属性名、属性值）

export var level1categorycoderelationnewattribute = function level1categorycoderelationnewattribute(params) {
  return post('/category/level1-category-code-relation-new-attribute', params, productConfig());
}; // 创建一级分类属性（二级分类、属性名、属性值）

export var level2categorycoderelationnewattribute = function level2categorycoderelationnewattribute(params) {
  return post('/category/level2-category-code-relation-new-attribute', params, productConfig());
};
export var level2categoryindex = function level2categoryindex(params) {
  return get('/category/level2-category-index', params, productConfig());
};
export var updatelevel2categoryrelation = function updatelevel2categoryrelation(params) {
  return post('/category/update-level2-category-relation', params, productConfig());
};
export var deletelevel2categoryrelation = function deletelevel2categoryrelation(params) {
  return post('/category/delete-level2-category-relation', params, productConfig());
}; // 修改属性名称

export var newattributeupdate = function newattributeupdate(params) {
  return post('/new-attribute/update', params, productConfig());
}; // 修改属性名称

export var getnewattributevaluebyattributevalues = function getnewattributevaluebyattributevalues(params) {
  return get('/new-attribute/get-by-attribute-values', params, productConfig());
}; //分类申报列表

export var categoryDeclarationindex = function categoryDeclarationindex(params) {
  return post('/categoryDeclaration/index', params, productConfig());
}; //保存分类申报

export var saveCategoryDeclaration = function saveCategoryDeclaration(params) {
  return post('/categoryDeclaration/saveCategoryDeclaration', params, productConfig());
}; //申报要素

export var categoryDeclarationElements = function categoryDeclarationElements(params) {
  return get('/categoryDeclaration/declarationElement', params, productConfig());
};
export var updateCategoryDeclarationElements = function updateCategoryDeclarationElements(params) {
  return post('/categoryDeclaration/updateDeclarationElement', params, productConfig());
}; //批量删除爬虫商品

export var reptileAliProductbatchDeleteProduct = function reptileAliProductbatchDeleteProduct(params) {
  return post('/reptileAliProduct/batchDelProduct', params, productConfig());
}; // 新增属性值

export var addnewattributevalue = function addnewattributevalue(params) {
  return post('/new-attribute-value/add', params, productConfig());
}; // 属性名称列表

export var newattributeindex = function newattributeindex(params) {
  return get('/new-attribute/index', params, productConfig());
};
export var newattributedelete = function newattributedelete(params) {
  return post('/new-attribute/delete', params, productConfig());
}; // Size Guide

export var sizeGuideHeadList = function sizeGuideHeadList(params) {
  return get('/size-guide/head', params, productConfig());
};
export var sizeGuideHeadAllActive = function sizeGuideHeadAllActive(params) {
  return get('/size-guide/head/all-active', params, productConfig());
};
export var createSizeGuideHead = function createSizeGuideHead(params) {
  return post('/size-guide/head', params, productConfig());
};
export var updateSizeGuideHead = function updateSizeGuideHead(params) {
  return post('/size-guide/update-head', params, productConfig());
};
export var deleteSizeGuideHead = function deleteSizeGuideHead(params) {
  return post('/size-guide/delete-head', params, productConfig());
};
export var sizeGuideTemplateList = function sizeGuideTemplateList(params) {
  return get('/size-guide/template', params, productConfig());
};
export var createSizeGuideTemplate = function createSizeGuideTemplate(params) {
  return post('/size-guide/template', params, productConfig());
};
export var updateSizeGuideTemplate = function updateSizeGuideTemplate(params) {
  return post('/size-guide/update-template', params, productConfig());
};
export var deleteSizeGuideTemplate = function deleteSizeGuideTemplate(params) {
  return post('/size-guide/delete-template', params, productConfig());
};
export var noTemplateCategories = function noTemplateCategories(params) {
  return get('/size-guide/template/categories/lowest', params, productConfig());
};
export var sizeGuideTemplateSelect = function sizeGuideTemplateSelect(params) {
  return get('/size-guide/template/select', params, productConfig());
}; //刊登商品到第三方平台

export var listingcreateThirdListingProduct = function listingcreateThirdListingProduct(params) {
  return post('/listing/createThridListingProduct', params, productListingConfig());
}; //shopify刊登创建

export var shopifycreateProduct = function shopifycreateProduct(params) {
  return post('/shopifyProduct/createProduct', params, productListingConfig());
}; //商品列表

export var shopifyProductlist = function shopifyProductlist(params) {
  return get('/shopifyProduct/list', params, productListingConfig());
}; //批量刊登商品

export var shopifyProductbatchListingProducts = function shopifyProductbatchListingProducts(params) {
  return post('/shopifyProduct/batchListingProducts', params, productListingConfig());
}; //获取shopify商品详情 草稿箱

export var shopifyProductdetail = function shopifyProductdetail(params) {
  return get('/shopifyProduct/detail', params, productListingConfig());
}; //更新Shopify商品资料 草稿箱

export var shopifyProductupdateProduct = function shopifyProductupdateProduct(params) {
  return post('/shopifyProduct/updateProduct', params, productListingConfig());
}; //获取店铺collections

export var shopifyProductcollections = oneCacheApi(function (params) {
  return get('/shopifyProduct/collections', params, productListingConfig());
}, 'account_code'); //批量修改SKU信息 本地

export var shopifyProductbatchUpdate = function shopifyProductbatchUpdate(params) {
  return post('/shopifyProduct/batchSkuData', params, productListingConfig());
}; //平台商品列表

export var onlineShopifyProductList = function onlineShopifyProductList(params) {
  return get('/onlineShopifyProduct/list', params, productListingConfig());
}; //平台商品列表导出

export var onlineShopifyProductExport = function onlineShopifyProductExport(params) {
  return get('/onlineShopifyProduct/export', params, productListingConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; //产品列表 - 在线

export var onlineShopifyVariationList = function onlineShopifyVariationList(params) {
  return get('/onlineShopifyVariation/list', params, productListingConfig());
}; //产品列表导出 - 在线

export var onlineShopifyVariationListExport = function onlineShopifyVariationListExport(params) {
  return get('/onlineShopifyVariation/export', params, productListingConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; //拉取在线商品 - 在线

export var shopifyProductpullShopifyOnline = function shopifyProductpullShopifyOnline(params) {
  return get('/onlineShopifyProduct/pullShopifyOnline', params, productListingConfig());
}; //获取拉取在线商品进度 - 在线

export var getPullShopifyOnlineProgress = function getPullShopifyOnlineProgress(params) {
  return get('/onlineShopifyProduct/getPullShopifyOnlineProgress', params, productListingConfig({
    loading: false
  }));
}; //批量修改在线商品刊登状态 - 在线

export var batchUpdatePublishedStatus = function batchUpdatePublishedStatus(params) {
  return post('/onlineShopifyProduct/batchUpdatePublishedStatus', params, productListingConfig());
}; //批量修改SKU信息 - 在线

export var onlineShopifyProductbatchSkuData = function onlineShopifyProductbatchSkuData(params) {
  return post('/onlineShopifyProduct/batchSkuData', params, productListingConfig());
}; //获取shopify商品详情  - 在线

export var onlineShopifyProductdetail = function onlineShopifyProductdetail(params) {
  return get('/onlineShopifyProduct/detail', params, productListingConfig());
}; //更新Shopify商品资料 - 在线

export var onlineShopifyProductupdateProduct = function onlineShopifyProductupdateProduct(params) {
  return post('/onlineShopifyProduct/updateProduct', params, productListingConfig());
}; //更新Shopify促销价 - 在线

export var onlineshopifysavepromotionprice = function onlineshopifysavepromotionprice(params) {
  return post('/online/promotion-price', params, productListingConfig());
}; //Shopify日志 - 在线

export var onlineshopifysavevariationlog = function onlineshopifysavevariationlog(params) {
  return get('/online/variation-log', params, productListingConfig());
}; //同步Shopify价格 - 在线

export var onlineshopifysyncprice = function onlineshopifysyncprice(params) {
  return post('/online/sync-shopify-price', params, productListingConfig());
}; // 刊登产品 - 在线

export var publishShopifyProductVariant = function publishShopifyProductVariant(params) {
  return post('/shopify/product/variant/publish', params, productListingHkConfig());
}; // 刊登图片 - 在线

export var publishShopifyProductImages = function publishShopifyProductImages(params) {
  return post('/shopify/product/image/publish', params, productListingHkConfig());
}; //售价计算表-列表

export var salepricecalculationindex = function salepricecalculationindex(params) {
  return get('/salePriceCalculation/index', params, productListingConfig());
}; //售价计算表-更新

export var salepricecalculationupdate = function salepricecalculationupdate(params) {
  return post('/salePriceCalculation/update', params, productListingConfig());
}; //售价计算表-更新价格

export var salepricecalculationupdateprice = function salepricecalculationupdateprice(params) {
  return post('/salePriceCalculation/updatePrice', params, productListingConfig());
}; //售价计算表-同步价格

export var salepricecalculationsyncprice = function salepricecalculationsyncprice(params) {
  return get('/salePriceCalculation/syncPrice', params, productListingConfig());
}; //售价尾数配置-列表

export var salepricecalculationmantissaindex = function salepricecalculationmantissaindex(params) {
  return get('/salePriceCalculationMantissa/index', params, productListingConfig());
}; //售价尾数配置-新增

export var salepricecalculationmantissacreate = function salepricecalculationmantissacreate(params) {
  return post('/salePriceCalculationMantissa/create', params, productListingConfig());
}; //售价尾数配置-更新

export var salepricecalculationmantissaupdate = function salepricecalculationmantissaupdate(params) {
  return post('/salePriceCalculationMantissa/update', params, productListingConfig());
}; //售价尾数配置-删除

export var salepricecalculationmantissadelete = function salepricecalculationmantissadelete(params) {
  return get('/salePriceCalculationMantissa/delete', params, productListingConfig());
}; // 洗护管理-列表

export var washprotectlist = function washprotectlist(params) {
  return get('/care/list', params, productConfig());
}; // 洗护管理-新建

export var washprotectcreate = function washprotectcreate(params) {
  return post('/care/create', params, productConfig());
}; // 洗护管理-编辑

export var washprotectedit = function washprotectedit(params) {
  return post('/care/edit', params, productConfig());
}; // 洗护管理-删除

export var washprotectdel = function washprotectdel(params) {
  return post('/care/delete', params, productConfig());
}; // 洗护模板-列表

export var washprotecttemplatelist = function washprotecttemplatelist(params) {
  return get('/care-template/list', params, productConfig());
}; // 洗护模板-创建

export var washprotecttemplatecreate = function washprotecttemplatecreate(params) {
  return post('/care-template/create', params, productConfig());
}; // 洗护模板-编辑

export var washprotecttemplateeidt = function washprotecttemplateeidt(params) {
  return post('/care-template/edit', params, productConfig());
}; // 洗护模板-详情

export var washprotecttemplatedetail = function washprotecttemplatedetail(params) {
  return post('/care-template/detail', params, productConfig());
}; // 所有洗护模板列表

export var washprotecttemplateall = function washprotecttemplateall(params) {
  return post('/care-template/all', params, productConfig());
}; // 产品系列

export var shopifyCollection = function shopifyCollection(params) {
  return get('/shopify/collection', params, productListingHkConfig());
}; // 产品系列列表

export var shopifyCollectionList = function shopifyCollectionList(params) {
  return get('/shopify/collections', params, productListingHkConfig());
}; // 产品系列排序

export var sortShopifyCollection = function sortShopifyCollection(params) {
  return post('/shopify/collection/sort', params, productListingHkConfig());
}; // 保存产品系列排序

export var saveShopifyCollectionSort = function saveShopifyCollectionSort(params) {
  return post('/shopify/collection/sort-save', params, productListingHkConfig());
}; // 分组规则列表

export var groupRuleList = function groupRuleList(params) {
  return get('/product/group-rule', params, productConfig());
}; // 分组规则配置

export var groupRuleConfig = function groupRuleConfig(params) {
  return get('/product/group-rule/config', params, productConfig());
}; // 创建分组规则

export var groupRuleCreate = function groupRuleCreate(params) {
  return post('/product/group-rule/create', params, productConfig());
}; // 更新分组规则

export var groupRuleUpdate = function groupRuleUpdate(params) {
  return post('/product/group-rule/update', params, productConfig());
}; // 更新分组规则状态

export var groupRuleUpdateStatus = function groupRuleUpdateStatus(params) {
  return post('/product/group-rule/update-status', params, productConfig());
}; // 导出 shopify tag

export var shopifyTagExport = function shopifyTagExport(params) {
  return get('/product/group-rule/export-shopify-tag', params, productConfig());
}; // 包装辅料要求-列表

export var packagingasklist = function packagingasklist(params) {
  return get('/packaging-ask/list', params, productConfig());
}; // 包装辅料要求-下拉选择

export var packagingaskselect = function packagingaskselect(params) {
  return get('/packaging-ask/select', params, productConfig());
}; // 包装辅料要求-创建

export var packagingaskcreate = function packagingaskcreate(params) {
  return post('/packaging-ask/create', params, productConfig());
}; // 包装辅料要求-更新

export var packagingaskupdate = function packagingaskupdate(params) {
  return post('/packaging-ask/update', params, productConfig());
}; // 包装辅料要求-删除

export var packagingaskdelete = function packagingaskdelete(params) {
  return get('/packaging-ask/delete', params, productConfig());
};
export var onlineShopifyProductTags = function onlineShopifyProductTags(params) {
  return get('/shopify/product/tags', params, productListingConfig());
};
export var onlineShopifyGroupCollections = function onlineShopifyGroupCollections(params) {
  return get('/shopify/group-collections', params, productListingConfig());
}; // 出货价加价配置-列表

export var markupsettinglist = function markupsettinglist(params) {
  return get('/mark-up-setting/list', params, productConfig());
}; // 出货价加价配置-创建

export var markupsettingcreate = function markupsettingcreate(params) {
  return post('/mark-up-setting/create', params, productConfig());
}; // 出货价加价配置-更新

export var markupsettingupdate = function markupsettingupdate(params) {
  return post('/mark-up-setting/update', params, productConfig());
}; // 出货价加价配置-删除

export var markupsettingdelete = function markupsettingdelete(params) {
  return get('/mark-up-setting/delete', params, productConfig());
}; // 面辅料-列表

export var accessorieslist = function accessorieslist(params) {
  return get('/accessories/index', params, productConfig());
}; // 面辅料-详情

export var accessoriesdetail = function accessoriesdetail(params) {
  return get('/accessories/detail', params, productConfig());
}; // 面辅料-创建

export var accessoriescreate = function accessoriescreate(params) {
  return post('/accessories/create', params, productConfig());
}; // 面辅料-更新

export var accessoriesupdate = function accessoriesupdate(params) {
  return post('/accessories/update', params, productConfig());
}; // 面辅料-批量更新 items

export var accessoriesbatchupdate = function accessoriesbatchupdate(params) {
  return post('/accessories/batch-update', params, productConfig());
}; // 面辅料-批量更新 ids

export var accessoriesbatchupdate2 = function accessoriesbatchupdate2(params) {
  return post('/accessories/batch-update2', params, productConfig());
}; // 面辅料-删除

export var accessoriesdelete = function accessoriesdelete(params) {
  return get('/accessories/delete', params, productConfig());
}; // 面辅料-导入

export var accessoriesimport = function accessoriesimport(params) {
  return post('/accessories/import', params, productConfig());
}; // 面辅料-导出

export var accessoriesexport = function accessoriesexport(params) {
  return get('/accessories/export', params, productConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; // 面辅料分类-搜索列表

export var accessoriescategorysearch = function accessoriescategorysearch(params) {
  return get('/accessories-category/search', params, productConfig());
}; // 面辅料分类-详情

export var accessoriescategorydetail = function accessoriescategorydetail(params) {
  return get('/accessories-category/detail', params, productConfig());
}; // 面辅料分类-分类树

export var accessoriescategorytree = function accessoriescategorytree(params) {
  return get('/accessories-category/tree', params, productConfig());
}; // 面辅料分类-创建

export var accessoriescategorycreate = function accessoriescategorycreate(params) {
  return post('/accessories-category/create', params, productConfig());
}; // 面辅料分类-更新

export var accessoriescategoryupdate = function accessoriescategoryupdate(params) {
  return post('/accessories-category/update', params, productConfig());
}; // 面辅料分类-更新状态

export var accessoriescategoryupdatestatus = function accessoriescategoryupdatestatus(params) {
  return post('/accessories-category/update-status', params, productConfig());
}; // 面辅料成分-列表

export var accessoriescomponentlist = function accessoriescomponentlist(params) {
  return get('/accessories-component/index', params, productConfig());
}; // 面辅料成分-创建

export var accessoriescomponentcreate = function accessoriescomponentcreate(params) {
  return post('/accessories-component/create', params, productConfig());
}; // 面辅料成分-更新

export var accessoriescomponentupdate = function accessoriescomponentupdate(params) {
  return post('/accessories-component/update', params, productConfig());
}; // 面辅料成分-删除

export var accessoriescomponentdelete = function accessoriescomponentdelete(params) {
  return get('/accessories-component/delete', params, productConfig());
}; // 面辅料-缸差-创建

export var accessoriesDyeLotChromatismCreate = function accessoriesDyeLotChromatismCreate(params) {
  return post('/accessories/dye-lot-chromatism', params, productConfig());
}; // 面辅料-缸差-删除

export var accessoriesDyeLotChromatismDelete = function accessoriesDyeLotChromatismDelete(params) {
  return post('/accessories/dye-lot-chromatism/delete', params, productConfig());
}; // 面辅料-缸差-商品添加

export var productDyeLotChromatismUpdate = function productDyeLotChromatismUpdate(params) {
  return post('/product/dye-lot-chromatism', params, productConfig());
}; // 异步导入文件列表

export var exportList = function exportList(params) {
  return get("/common/export/list", params, productConfig());
}; // 导入 Shopify 商品 SEO

export var importShopifyProductSeo = function importShopifyProductSeo(params, file) {
  return uploadNew('/shopify/product/seo/import', params, file, productListingConfig());
}; // 产品套装

export var createProductSuit = function createProductSuit(params) {
  return post('/product/suit', params, productConfig());
};
export var addProductSuitDetail = function addProductSuitDetail(params) {
  return post('/product/suit/detail/add', params, productConfig());
};
export var deleteProductSuitDetail = function deleteProductSuitDetail(params) {
  return post('/product/suit/detail/delete', params, productConfig());
};
export var productSuitList = function productSuitList(params) {
  return get('/product/suit', params, productConfig());
};
export var productSuitDetail = function productSuitDetail(params) {
  return get('/product/suit/detail', params, productConfig());
}; // 申报要素

export var customsDeclarationElement = function customsDeclarationElement(params) {
  return get('/customs/declaration-element', params, productConfig());
}; // SKC模特管理

export var skcModelList = function skcModelList(params) {
  return get('/product/skc-model', params, productConfig());
};
export var skcModelUpdateSyncStatus = function skcModelUpdateSyncStatus(params) {
  return post('/product/skc-model/update-sync-status', params, productConfig());
}; // 谷歌广告列表

export var googleConversionTrackSummaryList = function googleConversionTrackSummaryList(params) {
  return get('/google/ads/conversion-track', params, productListingConfig());
};
export var asyncImportGoogleConversionTrack = function asyncImportGoogleConversionTrack(params, file) {
  return uploadNew('/google/ads/conversion-track/async-import', params, file, productListingConfig());
};
export var exportGoogleConversionTrackSummary = function exportGoogleConversionTrackSummary(params) {
  return get('/google/ads/conversion-track/export', params, productListingConfig());
};
export var googleConversionTrend = function googleConversionTrend(params) {
  return get('/google/ads/conversion-track/conversion-trend', params, productListingConfig());
};
export var googleConversionUpdateStatus = function googleConversionUpdateStatus(params) {
  return post('/google/ads/conversion-track/google-ads-status', params, productListingConfig());
}; // 描述属性映射

export var newAttributeMap = function newAttributeMap(params) {
  return get('/new-attribute/map', params, productConfig());
}; //审版

export var skcApprovalList = function skcApprovalList(params) {
  return get('/product/skc-approval/index', params, productConfig());
};
export var getSkcApproval = function getSkcApproval(params) {
  return get('/product/skc-approval/get', params, productConfig());
};
export var batchCreateSkcApproval = function batchCreateSkcApproval(params) {
  return post('/product/skc-approval/batch-create', params, productConfig());
};
export var updateSkcApproval = function updateSkcApproval(params) {
  return post('/product/skc-approval/update', params, productConfig());
};
export var batchUpdateSkcApproval = function batchUpdateSkcApproval(params) {
  return post('/product/skc-approval/batch-update', params, productConfig());
};
export var skcApprovalCountStage = function skcApprovalCountStage(params) {
  return get('/product/skc-approval/count-stage', params, productConfig());
};
export var skcApprovalImport = function skcApprovalImport(params) {
  return post('/product/skc-approval/import', params, productConfig());
};
export var skcApprovalPrint = function skcApprovalPrint(params) {
  return get('/product/skc-approval/print', params, productConfig());
};
export var skcApprovalSkcSize = function skcApprovalSkcSize(params) {
  return get('/product/skc-approval/skc-size', params, productConfig());
};
export var skcApprovalExport = function skcApprovalExport(params) {
  return get('/product/skc-approval/export', params, productConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; //商品相关报表导出

export var reportExportProductRelate = function reportExportProductRelate(params) {
  return post('/report/export/product-relate', params, productConfig());
};