import { createRouteItem, layoutItem, createMeta } from "./unit.js"; //爬虫

var OrderList = function OrderList() {
  return import(
  /* webpackChunkName: 'order-list' */
  '@/views/order/list');
}; //订单列表


var OrderIssueProcess = function OrderIssueProcess() {
  return import(
  /* webpackChunkName: 'order-issue-process' */
  '@/views/order/issue-process');
}; //待处理问题


var OrderAberrantVerify = function OrderAberrantVerify() {
  return import(
  /* webpackChunkName: 'order-aberrant-verify' */
  '@/views/order/aberrant-verify');
}; //待处理问题


var OrderOutProduct = function OrderOutProduct() {
  return import(
  /* webpackChunkName: 'order-out-product' */
  '@/views/order/out-product');
}; //产品缺货订单明细


var OrderShipList = function OrderShipList() {
  return import(
  /* webpackChunkName: 'order-ship-list' */
  '@/views/order/ship-list');
}; //订单发货


var OrderDistribution = function OrderDistribution() {
  return import(
  /* webpackChunkName: 'order-distribution' */
  '@/views/order/distribution');
}; //订单配货


var OrderIssueRule = function OrderIssueRule() {
  return import(
  /* webpackChunkName: 'order-issue-rule' */
  '@/views/order/issue-rule');
}; //订单问题规则


var OrderLogisticsRule = function OrderLogisticsRule() {
  return import(
  /* webpackChunkName: 'order-logistics-rule' */
  '@/views/order/logistics-rule');
}; //订单物流规则


var OrderSplitPackageRule = function OrderSplitPackageRule() {
  return import(
  /* webpackChunkName: 'order-split-package-rule' */
  '@/views/order/logistics-rule/split-package');
}; //订单物流规则


var OrderDeclarationRule = function OrderDeclarationRule() {
  return import(
  /* webpackChunkName: 'order-declaration-rule' */
  '@/views/order/declaration-rule');
}; //申报规则


var OrderDetailIndex = function OrderDetailIndex() {
  return import(
  /* webpackChunkName: 'order-create' */
  '@/views/order/detail-view/detail-index');
}; // 订单明细-新版


var OrderCreate = function OrderCreate() {
  return import(
  /* webpackChunkName: 'order-create' */
  '@/views/order/create');
}; //新增订单


var OrderDistributionView = function OrderDistributionView() {
  return import(
  /* webpackChunkName: 'order-create' */
  '@/views/order/create/distribution-view');
}; //订单配货详情


var OrderTransferView = function OrderTransferView() {
  return import(
  /* webpackChunkName: 'order-create' */
  '@/views/order/create/transfer-view');
}; //虚拟订单查看


var OrderBlacklist = function OrderBlacklist() {
  return import(
  /* webpackChunkName: 'order-blacklist' */
  '@/views/order/blacklist');
}; //订单异常校验查看


var OrderCustomerList = function OrderCustomerList() {
  return import(
  /* webpackChunkName: 'order-customer-list' */
  '@/views/order/customer-list');
}; //客户信息管理


var OrderCustomerCreate = function OrderCustomerCreate() {
  return import(
  /* webpackChunkName: 'order-customer-create' */
  '@/views/order/customer-create');
}; //新增客户信息


var OrderReceiptManage = function OrderReceiptManage() {
  return import(
  /* webpackChunkName: 'order-receipt-manage' */
  '@/views/order/receipt-manage');
}; //付款单管理


var OrderVirtualShipping = function OrderVirtualShipping() {
  return import(
  /* webpackChunkName: 'order-virtual-shipping' */
  '@/views/order/virtual-shipping');
}; //虚拟订单


var OrderTransferList = function OrderTransferList() {
  return import(
  /* webpackChunkName: 'order-transfer-list' */
  '@/views/order/transfer-list');
}; //虚拟订单


var OrderMappingProduct = function OrderMappingProduct() {
  return import(
  /* webpackChunkName: 'order-mapping-product' */
  '@/views/order/mapping-product');
}; //商品映射关系


var OrderSalesReport = function OrderSalesReport() {
  return import(
  /* webpackChunkName: 'order-sales-report' */
  '@/views/order/sales-report');
}; //销售报表


var OrderSalesReportBySkc = function OrderSalesReportBySkc() {
  return import(
  /* webpackChunkName: 'order-sales-report' */
  '@/views/order/sales-report/index-by-skc');
}; //指定维度的销售报表


var OrderNewProductFollowUp = function OrderNewProductFollowUp() {
  return import(
  /* webpackChunkName: 'order-new-product-follow-up' */
  '@/views/order/new-product-follow-up');
}; //新品跟进表


var OrderSalesFollowUpForm = function OrderSalesFollowUpForm() {
  return import(
  /* webpackChunkName: 'order-sales-follow-up-form' */
  '@/views/order/sales-follow-up-form');
}; //新品动销跟进表


var GoogleConversionTrack = function GoogleConversionTrack() {
  return import(
  /* webpackChunkName: 'google-conversion-track' */
  '@/views/order/google-conversion-track');
}; //新品动销跟进表


var ServiceReasonMap = function ServiceReasonMap() {
  return import(
  /* webpackChunkName: 'service-reason-map' */
  '@/views/order/service-reason-map');
}; //客服服务原因管理列表


var OrderCustomerReturn = function OrderCustomerReturn() {
  return import(
  /* webpackChunkName: 'order-customer-return' */
  '@/views/order/customer-return');
}; //订单退货登记


var OrderCustomerService = function OrderCustomerService() {
  return import(
  /* webpackChunkName: 'order-customer-service' */
  '@/views/order/customer-service');
}; //订单客服登记
// 红人


var InternetCelebrityList = function InternetCelebrityList() {
  return import(
  /* webpackChunkName: 'order-internet-celebrity' */
  '@/views/order/internet-celebrity');
};

var InternetCelebrityCreate = function InternetCelebrityCreate() {
  return import(
  /* webpackChunkName: 'order-internet-celebrity-create' */
  '@/views/order/internet-celebrity/add');
}; // 红人订单列表


var InternetCelebrityOrderList = function InternetCelebrityOrderList() {
  return import(
  /* webpackChunkName: 'order-internet-celebrity-order' */
  '@/views/order/internet-celebrity/order');
}; //红人SPU选品报表


var popularPicks = function popularPicks() {
  return import(
  /* webpackChunkName: 'order-internet-celebrity-order' */
  '@/views/order/popular-picks');
};

var InternetCelebrityOrderCreate = function InternetCelebrityOrderCreate() {
  return import(
  /* webpackChunkName: 'order-internet-celebrity-order-create' */
  '@/views/order/internet-celebrity/order/add');
}; // 运营报表


var OperationalReportList = function OperationalReportList() {
  return import(
  /* webpackChunkName: 'order-operational-report' */
  '@/views/order/operational-report');
}; // 国家ROI


var countryRoi = function countryRoi() {
  return import(
  /* webpackChunkName: 'order-internet-celebrity-report-country-roi' */
  '@/views/order/internet-celebrity/report/country-roi');
}; // 红人销量看板


var internetCelebritySalesReport = function internetCelebritySalesReport() {
  return import(
  /* webpackChunkName: 'order-internet-celebrity-report-sales-report' */
  '@/views/order/internet-celebrity/report/sales-report');
}; // 红人谈成看板


var internetCelebrityNegotiationReport = function internetCelebrityNegotiationReport() {
  return import(
  /* webpackChunkName: 'order-internet-celebrity-report-negotiation-report' */
  '@/views/order/internet-celebrity/report/negotiation-report');
}; // 国家ROI


var countryRoiStat = function countryRoiStat() {
  return import(
  /* webpackChunkName: 'order-country-roi-stat' */
  '@/views/order/country-roi-stat');
};

var CustomerGroupRuleList = function CustomerGroupRuleList() {
  return import("@/views/order/customer/group-rule");
}; // 分组规则
// 毛利报表


var BrandGradeProfit = function BrandGradeProfit() {
  return import(
  /* webpackChunkName: 'order-brand-grade-profit' */
  '@/views/order/brand-grade-profit');
}; //分类销存报表


var CategorySalesInventory = function CategorySalesInventory() {
  return import(
  /* webpackChunkName: 'order-category-sales-inventory' */
  '@/views/order/category-sales-inventory');
};

var AfterSalesReport = function AfterSalesReport() {
  return import("@/views/order/after-sale");
}; // 售后报表
// 红人请款列表


var IcPaymentList = function IcPaymentList() {
  return import(
  /* webpackChunkName: 'order-internet-celebrity-payment' */
  '@/views/order/internet-celebrity/payment');
}; // 红人请款列表（财务）


var IcFinanceList = function IcFinanceList() {
  return import(
  /* webpackChunkName: 'order-internet-celebrity-payment-finance' */
  '@/views/order/internet-celebrity/payment/finance-index');
}; // 红人标签


var IcTagTree = function IcTagTree() {
  return import(
  /* webpackChunkName: 'order-internet-celebrity-tag' */
  '@/views/order/internet-celebrity/tag');
}; // 订单待配货列表
//订单相关


export default layoutItem('/order', [//订单列表
createRouteItem('order-list', 'order-list', OrderList, createMeta('订单列表')), // 订单明细-新版
createRouteItem('order-detail-index', 'order-detail-index', OrderDetailIndex, createMeta('查看订单', 'order-list', 'order-view')), //新增订单
createRouteItem('order-create', 'order-create', OrderCreate, createMeta('新增订单', 'order-list')), //待处理问题
createRouteItem('order-issue-process', 'order-issue-process', OrderIssueProcess, createMeta('待处理问题')), //订单异常校验
createRouteItem('order-aberrant-verify', 'order-aberrant-verify', OrderAberrantVerify, createMeta('订单异常校验')), //缺货产品列表
createRouteItem('order-out-product', 'order-out-product', OrderOutProduct, createMeta('缺货产品列表')), //订单发货
createRouteItem('order-ship-list', 'order-ship-list', OrderShipList, createMeta('订单发货')), //订单待配货列表
createRouteItem('order-distribution', 'order-distribution', OrderDistribution, createMeta('订单待配货列表')), //订单待配货列表
createRouteItem('order-distribution-view', 'order-distribution-view', OrderDistributionView, createMeta('待配货订单', 'order-distribution')), //订单问题规则
createRouteItem('order-issue-rule', 'order-issue-rule', OrderIssueRule, createMeta('订单问题规则')), // 订单物流规则
createRouteItem('order-logistics-rule', 'order-logistics-rule', OrderLogisticsRule, createMeta('订单物流规则')), // 订单拆包规则
createRouteItem('order-split-package-rule', 'order-split-package-rule', OrderSplitPackageRule, createMeta('订单拆包规则')), // 申报规则
createRouteItem('order-declaration-rule', 'order-declaration-rule', OrderDeclarationRule, createMeta('产品申报规则')), //订单问题规则
createRouteItem('order-blacklist', 'order-blacklist', OrderBlacklist, createMeta('黑名单管理')), //客户信息管理
createRouteItem('order-customer-list', 'order-customer-list', OrderCustomerList, createMeta('客户信息管理')), //新增客户信息
createRouteItem('order-customer-create', 'order-customer-create', OrderCustomerCreate, createMeta('新增客户信息', 'order-customer-list')), //付款单管理
createRouteItem('receipt-manage', 'order-receipt-manage', OrderReceiptManage, createMeta('付款单管理')), //订单虚拟发货
createRouteItem('virtual-shipping', 'order-virtual-shipping', OrderVirtualShipping, createMeta('订单虚拟发货')), //转单列表
createRouteItem('transfer-list', 'order-transfer-list', OrderTransferList, createMeta('转单列表')), //转单查看
createRouteItem('transfer-view', 'order-transfer-view', OrderTransferView, createMeta('转单查看', 'order-transfer-list')), //商品映射关系
createRouteItem('mapping-product', 'order-mapping-product', OrderMappingProduct, createMeta('商品映射关系')), //销售报表
createRouteItem('sales-report', 'order-sales-report', OrderSalesReport, createMeta('销售报表')), // 新品跟进表
createRouteItem('new-product-follow-up', 'order-new-product-follow-up', OrderNewProductFollowUp, createMeta('新品跟进表')), // 动销跟进表
createRouteItem('sales-follow-up-form', 'order-sales-follow-up-form', OrderSalesFollowUpForm, createMeta('动销跟进表')), // 谷歌广告转化表
createRouteItem('google-conversion-track', 'google-conversion-track', GoogleConversionTrack, createMeta('谷歌广告转化表')), createRouteItem('sales-report-by-skc', 'sales-report-index-by-skc', OrderSalesReportBySkc, createMeta('SKC动销报表')), // 客户服务原因管理
createRouteItem('service-reason-map', 'service-reason-map', ServiceReasonMap, createMeta('服务原因')), //订单退货登记
createRouteItem('customer-return', 'order-customer-return', OrderCustomerReturn, createMeta('订单退货登记')), //订单客服登记
createRouteItem('customer-service', 'order-customer-service', OrderCustomerService, createMeta('订单客服登记')), //红人列表
createRouteItem('internet-celebrity', 'order-internet-celebrity', InternetCelebrityList, createMeta('红人列表')), //新增红人
createRouteItem('internet-celebrity-create', 'order-internet-celebrity-create', InternetCelebrityCreate, createMeta('新增红人', 'order-internet-celebrity', '', {
  cacheView: false
})), //红人订单列表
createRouteItem('internet-celebrity-order', 'order-internet-celebrity-order', InternetCelebrityOrderList, createMeta('红人订单列表')), //红人SPU选品报表
createRouteItem('popular-picks', 'order-popular-picks', popularPicks, createMeta('红人SPU选品报表')), createRouteItem('internet-celebrity-order-create', 'order-internet-celebrity-order-create', InternetCelebrityOrderCreate, createMeta('新增红人订单', 'order-internet-celebrity-order', '', {
  cacheView: false
})), //运营报表
createRouteItem('operational-report', 'order-operational-report', OperationalReportList, createMeta('运营报表')), //红人国家ROI
createRouteItem('country-roi', 'order-country-roi', countryRoi, createMeta('红人国家ROI')), //红人销量看板
createRouteItem('internet-celebrity-sales-report', 'internet-celebrity-sales-report', internetCelebritySalesReport, createMeta('红人销量看板')), //红人销量看板
createRouteItem('internet-celebrity-negotiation-report', 'internet-celebrity-negotiation-report', internetCelebrityNegotiationReport, createMeta('红人谈成看板')), //国家ROI
createRouteItem('country-roi-stat', 'order-country-roi-stat', countryRoiStat, createMeta('国家细分ROI')), // 用户分组规则管理
createRouteItem('customer-group-rule', 'customer-group-rule', CustomerGroupRuleList, createMeta('用户分组规则')), //毛利报表
createRouteItem('brand-grade-profit', 'order-brand-grade-profit', BrandGradeProfit, createMeta('毛利报表')), //分类销存报表
createRouteItem('category-sales-inventory', 'order-category-sales-inventory', CategorySalesInventory, createMeta('类目销存报表')), //售后报表
createRouteItem('after-sales-report', 'order-after-sales-report', AfterSalesReport, createMeta('售后报表')), // 红人请款
createRouteItem('internet-celebrity-payment', 'order-internet-celebrity-payment', IcPaymentList, createMeta('请款列表')), // 红人请款(财务)
createRouteItem('internet-celebrity-payment-finance', 'order-internet-celebrity-payment-finance', IcFinanceList, createMeta('红人付款审批')), // 红人标签
createRouteItem('internet-celebrity-tag', 'order-internet-celebrity-tag', IcTagTree, createMeta('红人标签'))]);