import { get, post, stockConfig, uploadNew } from '@/api';
/**
 * TODO 这是关于仓库的api
 */
//仓库列表

export var stockindex = function stockindex(params) {
  return get('/v1/stock/index', params, stockConfig());
};
export var stockcreate = function stockcreate(params) {
  return post('/v1/stock/create', params, stockConfig());
};
export var stockupdate = function stockupdate(params) {
  return post('/v1/stock/update', params, stockConfig());
}; //货位管理

export var managementindex = function managementindex(params) {
  return get('/v1/container/index', params, stockConfig());
};
export var managementcreate = function managementcreate(params) {
  return post('/v1/container/create', params, stockConfig());
};
export var managementupdate = function managementupdate(params) {
  return post('/v1/container/update', params, stockConfig());
};
export var managementimport = function managementimport(params) {
  return post('/v1/container/import', params, stockConfig());
}; //当前租户下的仓库列表，适用下拉框列表

export var stocklist = function stocklist(params) {
  return get('/v1/stock/stock-list', params, stockConfig());
};
export var stockaccessoriesstocklist = function stockaccessoriesstocklist(params) {
  return get('/v1/stock/accessories-stock-list', params, stockConfig());
}; //面辅料仓库列表

export var stockcodewithcontainnolist = function stockcodewithcontainnolist(params) {
  return get('/v1/container/stock_code', params, stockConfig());
}; //产库对应的货位号
//收货管理

export var receiptindex = function receiptindex(params) {
  return get('/v1/receipt/index', params, stockConfig());
}; //收货单-详情/打印

export var receiptdetails = function receiptdetails(params) {
  return get('/v1/receipt/details', params, stockConfig());
};
export var batchReceiptDetails = function batchReceiptDetails(params) {
  return get('/v1/receipt/batch-details', params, stockConfig());
}; //收货单-详情列表-新版

export var receiptdetails2 = function receiptdetails2(params) {
  return get('/v1/receipt/detail-index', params, stockConfig());
}; //收货单-详情列表-批量导出

export var exportReceiptDetail = function exportReceiptDetail(params) {
  return get('/v1/receipt/export-detail-index', params, stockConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; //收货单确认差异

export var receiptdiffer = function receiptdiffer(params) {
  return post('/v1/receipt/differ', params, stockConfig());
}; //收货单收货入库

export var receiptconfirm = function receiptconfirm(params) {
  return post('/v1/receipt/confirm', params, stockConfig());
}; //导入创建收货单

export var receiptimport = function receiptimport(params) {
  return post('/v1/receipt/import', params, stockConfig());
}; // 收货单 - 签收

export var signReceipt = function signReceipt(params) {
  return post('/v1/receipt/sign', params, stockConfig());
}; //上架管理

export var shelfindex = function shelfindex(params) {
  return get('/v1/shelf/index', params, stockConfig());
};
export var accessoriesShelfIndex = function accessoriesShelfIndex(params) {
  return get('/v1/accessories/shelf/index', params, stockConfig());
}; //上架管理-详情/打印

export var shelfdetails = function shelfdetails(params) {
  return get('/v1/shelf/details', params, stockConfig());
};
export var accessoriesShelfDetails = function accessoriesShelfDetails(params) {
  return get('/v1/accessories/shelf/details', params, stockConfig());
}; //上架管理-详情列表-新版

export var shelfdetails2 = function shelfdetails2(params) {
  return get('/v1/shelf/detail-index', params, stockConfig());
};
export var accessoriesShelfDetailIndex = function accessoriesShelfDetailIndex(params) {
  return get('/v1/accessories/shelf/detail-index', params, stockConfig());
}; //上架管理-上架操作

export var shelfconfirm = function shelfconfirm(params) {
  return post('/v1/shelf/put-shelf', params, stockConfig());
};
export var accessoriesShelfConfirm = function accessoriesShelfConfirm(params) {
  return post('/v1/accessories/shelf/put-shelf', params, stockConfig());
}; //上架单确认差异

export var shelfdiffer = function shelfdiffer(params) {
  return get('/v1/shelf/differ', params, stockConfig());
};
export var accessoriesShelfDiffer = function accessoriesShelfDiffer(params) {
  return get('/v1/accessories/shelf/differ', params, stockConfig());
}; //上架单日志

export var shelfTrack = function shelfTrack(params) {
  return get('/v1/shelf/shelf-track', params, stockConfig({
    loading: false
  }));
};
export var accessoriesShelfTrack = function accessoriesShelfTrack(params) {
  return get('/v1/accessories/shelf/shelf-track', params, stockConfig({
    loading: false
  }));
}; //上架单-详情列表-批量导出

export var exportShelfDetail = function exportShelfDetail(params) {
  return get('/v1/shelf/export-detail-index', params, stockConfig({
    other: {
      responseType: 'blob'
    }
  }));
};
export var exportAccessoriesShelfDetail = function exportAccessoriesShelfDetail(params) {
  return get('/v1/accessories/shelf/export-detail-index', params, stockConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; //上架单-上传凭证

export var shelfvoucher = function shelfvoucher(params) {
  return post('/v1/shelf/voucher', params, stockConfig());
};
export var accessoriesShelfVoucher = function accessoriesShelfVoucher(params) {
  return post('/v1/accessories/shelf/voucher', params, stockConfig());
}; //货位库存明细表

export var inventorystocklist = function inventorystocklist(params) {
  return get('/v1/inventory/stock-list', params, stockConfig());
};
export var accessoriesContainerStockList = function accessoriesContainerStockList(params) {
  return get('/v1/accessories/container/stock/list', params, stockConfig());
}; //货位库存变动日志

export var inventorystocktracklist = function inventorystocktracklist(params) {
  return get('/v1/inventory/stock-track-list', params, stockConfig());
};
export var accessoriesContainerStockTrack = function accessoriesContainerStockTrack(params) {
  return get('/v1/accessories/container/stock/track', params, stockConfig());
}; //产品库存明细

export var inventorygoodsstocklist = function inventorygoodsstocklist(params) {
  return get('/v1/inventory/goods-stock-list', params, stockConfig());
};
export var accessoriesStockList = function accessoriesStockList(params) {
  return get('/v1/accessories/stock/list', params, stockConfig());
}; //产品库存明细导出

export var inventorygoodsstockexport = function inventorygoodsstockexport(params) {
  return get('/v1/inventory/goods-stock-export', params, stockConfig());
};
export var exportAccessoriesStockList = function exportAccessoriesStockList(params) {
  return get('/v1/accessories/stock/list/export', params, stockConfig());
}; //货位库存明细导出

export var inventoryGoodsContainerStockExport = function inventoryGoodsContainerStockExport(params) {
  return get('/v1/inventory/stock-export', params, stockConfig({
    other: {
      responseType: 'blob'
    }
  }));
};
export var accessoriesContainerStockExport = function accessoriesContainerStockExport(params) {
  return get('/v1/accessories/container/stock/list/export', params, stockConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; //产品库存变动日志

export var inventorystocktrackdetails = function inventorystocktrackdetails(params) {
  return get('/v1/inventory/goods-stock-details', params, stockConfig());
};
export var accessoriesStockTrack = function accessoriesStockTrack(params) {
  return get('/v1/accessories/stock/track', params, stockConfig());
}; //盘点单列表

export var inventorylist = function inventorylist(params) {
  return get('/v1/inventory/get-inventory-list', params, stockConfig());
}; //创建盘点单

export var inventoryimport = function inventoryimport(params) {
  return post('/v1/inventory/import', params, stockConfig());
}; //获取盘点单明细

export var inventorydetails = function inventorydetails(params) {
  return get('/v1/inventory/get-inventory-details', params, stockConfig());
}; //盘盈盘亏明细表

export var inventorystocktakinglist = function inventorystocktakinglist(params) {
  return get('/v1/inventory/stocktaking-list', params, stockConfig());
}; //确定盘点

export var inventorystocktaking = function inventorystocktaking(params) {
  return post('/v1/inventory/stocktaking', params, stockConfig());
}; //库存移动列表

export var inventorystockmovelist = function inventorystockmovelist(params) {
  return get('/v1/inventory/stock-move-list', params, stockConfig());
}; //库存移动导入

export var inventorymoveimport = function inventorymoveimport(params) {
  return post('/v1/inventory/move-import', params, stockConfig());
}; //库存移动导出

export var inventorymoveexport = function inventorymoveexport(params) {
  return get('/v1/inventory/move-export', params, stockConfig());
}; //仓库所有货位

export var inventorycontainerlist = function inventorycontainerlist(params) {
  return get('/v1/inventory/container-list', params, stockConfig());
}; //仓库货位，支持查询和分页

export var inventorycontainerlist2 = function inventorycontainerlist2(params, config) {
  return get('/v1/inventory/container-index', params, stockConfig(config));
}; //检查产品编码在不在仓库

export var inventorygetcontainerstock = function inventorygetcontainerstock(params) {
  return get('/v1/inventory/get-container-stock', params, stockConfig());
}; //检查产品编码在不在仓库

export var inventorycreatecontainermove = function inventorycreatecontainermove(params) {
  return post('/v1/inventory/create-container-move', params, stockConfig());
}; //库存移动详情

export var inventorystockmovedetails = function inventorystockmovedetails(params) {
  return get('/v1/inventory/stock-move-details', params, stockConfig());
}; //执行库存移动

export var inventorystockmove = function inventorystockmove(params) {
  return post('/v1/inventory/stock-move', params, stockConfig());
}; //库存移动完结

export var inventorystockmoveend = function inventorystockmoveend(params) {
  return get('/v1/inventory/stock-move-end', params, stockConfig());
}; //出库单-列表

export var outboundindex = function outboundindex(params) {
  return get('/v1/outbound/index', params, stockConfig());
}; //出库单-创建出库单

export var outboundimport = function outboundimport(params) {
  return post('/v1/outbound/import', params, stockConfig());
}; //出库单-导入跟踪号

export var outboundimportlogistics = function outboundimportlogistics(params) {
  return post('/v1/outbound/import-logistics', params, stockConfig());
}; //出库单-导入物流轨迹

export var outboundimportlogisticstrack = function outboundimportlogisticstrack(params) {
  return post('/v1/outbound/import-logistics-track', params, stockConfig());
}; //出库单-详情/打印

export var outboundview = function outboundview(params) {
  return get('/v1/outbound/view', params, stockConfig());
}; //出库单-详情/打印

export var outboundreview = function outboundreview(params) {
  return get('/v1/outbound/review', params, stockConfig());
}; //出库单-确认异常

export var outbounddiffer = function outbounddiffer(params) {
  return post('/v1/outbound/differ', params, stockConfig());
}; //出库单-修改对账状态

export var outboundmodifyreconciliation = function outboundmodifyreconciliation(params) {
  return post('/v1/outbound/modify-reconciliation', params, stockConfig());
}; //根据编码获取最新汇率

export var newestExchangeRateByCode = function newestExchangeRateByCode(params) {
  return get('/v1/outbound/newest-exchange-code', params, stockConfig());
}; //集单未拣货-出库异常

export var outboundpickoutdiffer = function outboundpickoutdiffer(params) {
  return post('/v1/outbound/pick-out-diff', params, stockConfig());
}; //出库单-确认完成

export var outboundconfirm = function outboundconfirm(params) {
  return get('/v1/outbound/confirm', params, stockConfig());
}; //出库单-异常列表

export var outbounddifferindex = function outbounddifferindex(params) {
  return get('/v1/outbound-differ/index', params, stockConfig());
}; //出库单-作废/取消异常

export var outbounddiffercancel = function outbounddiffercancel(params) {
  return post('/v1/outbound-differ/cancel', params, stockConfig());
}; //出库单-导出物流预报数据

export var outboundlogisticsexport = function outboundlogisticsexport(params) {
  return get('/v1/outbound/export-logistics', params, stockConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; //出库单-导出物流预报数据

export var outboundlogisticsexport2 = function outboundlogisticsexport2(params) {
  return get('/v1/outbound/export-logistics2', params, stockConfig());
}; //收货单-更新打印状态

export var receiptprintstatus = function receiptprintstatus(params) {
  return post('/v1/receipt/print-status', params, stockConfig());
}; // 出库单详情列表

export var outbounddetailsindex = function outbounddetailsindex(params) {
  return get('/v1/outbound/details-index', params, stockConfig());
}; //质检单-列表

export var qcindex = function qcindex(params) {
  return get('/v1/qc/index', params, stockConfig());
}; //质检单-详情/打印/查看

export var qcdetails = function qcdetails(params) {
  return get('/v1/qc/details', params, stockConfig());
}; //质检单-详情列表-新版

export var qcdetails2 = function qcdetails2(params) {
  return get('/v1/qc/detail-index', params, stockConfig());
}; //质检单-质检完成

export var qcconfirm = function qcconfirm(params) {
  return post('/v1/qc/confirm', params, stockConfig());
}; //质检单-单个SKU质检

export var qcconfirmone = function qcconfirmone(params) {
  return post('/v1/qc/confirm-one', params, stockConfig());
}; //质检单-详情列表-批量导出

export var exportQcDetail = function exportQcDetail(params) {
  return get('/v1/qc/export-detail-index', params, stockConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; //质检单-上传凭证

export var qcvoucher = function qcvoucher(params) {
  return post('/v1/qc/voucher', params, stockConfig());
}; //质检改善单列表

export var qcimproveindex = function qcimproveindex(params) {
  return get('/v1/qc/improve-index', params, stockConfig());
}; //质检改善单详情

export var qcimprovedetail = function qcimprovedetail(params) {
  return get('/v1/qc/improve-detail', params, stockConfig());
}; //质检改善单更新

export var qcimproveupdate = function qcimproveupdate(params) {
  return post('/v1/qc/improve-update', params, stockConfig());
}; //质检改善单导出

export var qcimproveexport = function qcimproveexport(params) {
  return get('/v1/qc/improve-export', params, stockConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; // 抽检规则

export var samplingRules = function samplingRules(params) {
  return get('/v1/qc/sampling-rules', params, stockConfig());
}; //上门质检-质检

export var doorqccheck = function doorqccheck(params) {
  return post('/v1/door-qc/check', params, stockConfig());
}; //上门质检-全部质检

export var doorqcallcheck = function doorqcallcheck(params) {
  return post('/v1/door-qc/all-check', params, stockConfig());
}; //上门质检-记录

export var doorqcrecordlist = function doorqcrecordlist(params) {
  return get('/v1/door-qc/record-list', params, stockConfig());
}; //上门质检-打印质检单

export var doorqcprint = function doorqcprint(params) {
  return get('/v1/door-qc/print', params, stockConfig());
}; //上门质检-明细列表-导出

export var doorqcexport = function doorqcexport(params) {
  return get('/v1/door-qc/export', params, stockConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; //出库单-打印面单

export var outboundprintface = function outboundprintface(params) {
  return get('/v1/outbound/print-face', params, stockConfig());
}; //出库单-批量打印面单

export var outboundprintfacebatch = function outboundprintfacebatch(params) {
  return get('/v1/outbound/batch-print-face', params, stockConfig());
}; //出库单-打印发票

export var outboundBatchPrintBill = function outboundBatchPrintBill(params) {
  return get('/v1/outbound/batch-print-bill', params, stockConfig());
}; //出库单-导出发票

export var outboundexportinvoice = function outboundexportinvoice(params) {
  return get('/v1/outbound/export-invoice', params, stockConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; // 出库单-上传面单和跟踪单号

export var uploadLogisticInfo = function uploadLogisticInfo(params) {
  return post('/v1/outbound/upload-logistic-info', params, stockConfig());
}; //签收报表

export var outboundsignforreportindex = function outboundsignforreportindex(params) {
  return get('/v1/outbound/sign-for-report/index', params, stockConfig());
}; //签收报表导出

export var outboundsignforreportexport = function outboundsignforreportexport(params) {
  return get('/v1/outbound/sign-for-report/export', params, stockConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; //拣货单列表

export var outboundpickindex = function outboundpickindex(params) {
  return get('/v1/outbound/pick-list', params, stockConfig());
}; //拣货单明细

export var outboundpickdetail = function outboundpickdetail(params) {
  return get('/v1/outbound/pick-detail', params, stockConfig());
}; //生成拣货单

export var outboundcreatepick = function outboundcreatepick(params) {
  return post('/v1/outbound/create-pick', params, stockConfig());
}; //二次分拣-页面

export var outboundsecondpickindex = function outboundsecondpickindex(params) {
  return get('/v1/outbound/second-pick-index', params, stockConfig());
}; //二次分拣

export var outboundsecondpick = function outboundsecondpick(params) {
  return post('/v1/outbound/second-pick', params, stockConfig());
}; //打印拣货单

export var outboundprintpick = function outboundprintpick(params) {
  return get('/v1/outbound/print-pick', params, stockConfig());
}; //打印出库单

export var outboundprintout = function outboundprintout(params) {
  return get('/v1/outbound/print-out', params, stockConfig());
}; //拣货出库明细

export var outboundpickoutdetail = function outboundpickoutdetail(params) {
  return get('/v1/outbound/pick-out-detail', params, stockConfig());
}; //拣货出库明细

export var outboundReturnBack = function outboundReturnBack(params) {
  return post('/v1/outbound/return-back', params, stockConfig());
}; // 拣货跟踪

export var outboundPickOutTrack = function outboundPickOutTrack(params) {
  return post('/v1/outbound/pick-out-track', params, stockConfig());
}; //库区列表

export var reservoirlist = function reservoirlist(params) {
  return post('/v1/reservoir/list', params, stockConfig());
}; //库区新增

export var reservoiradd = function reservoiradd(params) {
  return post('/v1/reservoir/add', params, stockConfig());
}; //库区编辑

export var reservoiredit = function reservoiredit(params) {
  return post('/v1/reservoir/edit', params, stockConfig());
}; //当前租户所有库区

export var getAllReservoir = function getAllReservoir(params) {
  return get('/v1/reservoir/getAllReservoir', params, stockConfig());
}; //打印货品标签

export var containergoodslabel = function containergoodslabel(params) {
  return get('/v1/container/goods-label', params, stockConfig());
}; //批量打印货品标签

export var containerBatchGoodsLabel = function containerBatchGoodsLabel(params) {
  return get('/v1/container/batch-goods-label', params, stockConfig());
}; //出库包装

export var packageOutbound = function packageOutbound(params) {
  return post('/v1/outbound/packageOutbound', params, stockConfig());
}; //货位分配列表

export var allocationlist = function allocationlist(params) {
  return post('/v1/allocation/list', params, stockConfig());
}; //产品货位删除

export var allocationdelete = function allocationdelete(params) {
  return post('/v1/allocation/delete', params, stockConfig());
}; //产品货位删除

export var allocationconfirm = function allocationconfirm(params) {
  return post('/v1/allocation/confirm', params, stockConfig());
}; //货位分配单个添加

export var allocationadd = function allocationadd(params) {
  return post('/v1/allocation/add', params, stockConfig());
}; //产品货位分配导入

export var allocationimport = function allocationimport(params) {
  return post('/v1/allocation/import', params, stockConfig());
}; //货位回收列表

export var recyclelist = function recyclelist(params) {
  return post('/v1/recycle/list', params, stockConfig());
}; //货位回收添加

export var recycleadd = function recycleadd(params) {
  return post('/v1/recycle/add', params, stockConfig());
}; //货位回收

export var recycleupdate = function recycleupdate(params) {
  return post('/v1/recycle/update', params, stockConfig());
}; //补货管理

export var replenishmentlist = function replenishmentlist(params) {
  return post('/v1/replenishment/list', params, stockConfig());
}; //补货详情

export var replenishmentdetail = function replenishmentdetail(params) {
  return get('/v1/replenishment/detail', params, stockConfig());
}; //补货确认

export var replenishmentconfirm = function replenishmentconfirm(params) {
  return post('/v1/replenishment/confirm', params, stockConfig());
}; //补货单打印

export var replenishmentprint = function replenishmentprint(params) {
  return get('/v1/replenishment/print', params, stockConfig());
}; //出库单批量打印

export var printoutbound = function printoutbound(params) {
  return post('/v1/outbound/print-outbound', params, stockConfig());
}; //多批次出库打印

export var outboundprintbatch = function outboundprintbatch(params) {
  return post('/v1/outbound/print-batch', params, stockConfig());
}; //多批次出库打印

export var defaultstock = function defaultstock(params) {
  return get('/v1/stock/default-stock', params, stockConfig());
}; //收货明细--打印二维码

export var receiptListPrint = function receiptListPrint(params) {
  return get('/v1/receipt/qr-print', params, stockConfig());
}; // 质检-不良品处理列表

export var stockBadProcessList = function stockBadProcessList(params) {
  return get('/v1/qc/return-goods-order-index', params, stockConfig());
}; // 质检-不良品处理列表 导出

export var stockBadProcessExport = function stockBadProcessExport(params) {
  return get('/v1/qc/return-goods-order-export', params, stockConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; // 质检-退货单明细

export var stockReturnsDetail = function stockReturnsDetail(params) {
  return get('/v1/qc/return-goods-order-detail', params, stockConfig());
}; // 质检-填写退货单

export var fillInReturnOrder = function fillInReturnOrder(params) {
  return get('/v1/qc/ship-return-goods-order', params, stockConfig());
}; // 质检-不良品列表-打印退货单

export var printReturnOrder = function printReturnOrder(params) {
  return get('/v1/qc/print-return-goods-order', params, stockConfig());
}; // 质检-不良品列表-批量打印退货单详情

export var batchReturnsDetail = function batchReturnsDetail(params) {
  return get('/v1/qc/batch-return-goods-order-detail', params, stockConfig());
}; // 质检-不良品列表-批量获取物流信息详情

export var batchReturnsLogisticsDetail = function batchReturnsLogisticsDetail(params) {
  return get('/v1/qc/combine-ship-return-goods-order-detail', params, stockConfig());
}; // 质检-不良品列表-批量打印退货单

export var batchPrintReturnOrder = function batchPrintReturnOrder(params) {
  return get('/v1/qc/batch-print-return-goods-order', params, stockConfig());
}; // 质检-不良品列表-合并填写退货单

export var batchFillReturnOrder = function batchFillReturnOrder(params) {
  return get('/v1/qc/combine-ship-return-goods-order', params, stockConfig());
}; // 进销存月统计-总表

export var invoicingMonthStatList = function invoicingMonthStatList(params) {
  return get('/v1/goods-invoicing/month-stat-list', params, stockConfig());
}; // 进销存月统计-总表-导出

export var invoicingMonthStatExport = function invoicingMonthStatExport(params) {
  return get('/v1/goods-invoicing/month-stat-export', params, stockConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; // 进销存记录-明细表

export var invoicingRecordList = function invoicingRecordList(params) {
  return get('/v1/goods-invoicing/record-list', params, stockConfig());
}; // 进销存记录-明细表-导出

export var invoicingRecordExport = function invoicingRecordExport(params) {
  return get('/v1/goods-invoicing/record-export', params, stockConfig());
}; // 库存月统计-总库存

export var invoicingStockMonthStatList = function invoicingStockMonthStatList(params) {
  return get('/v1/goods-invoicing/stock-month-stat-list', params, stockConfig());
}; // 库存月统计-总库存-导出

export var invoicingStockMonthStatExport = function invoicingStockMonthStatExport(params) {
  return get('/v1/goods-invoicing/stock-month-stat-export', params, stockConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; // 周期数据列表

export var invoicingcyclelist = function invoicingcyclelist(params) {
  return get('/v1/goods-invoicing/cycle-list', params, stockConfig());
}; // 添加周期数据

export var invoicingcyclecreate = function invoicingcyclecreate(params) {
  return post('/v1/goods-invoicing/cycle-create', params, stockConfig());
}; // 更新周期数据

export var invoicingcycleupdate = function invoicingcycleupdate(params) {
  return post('/v1/goods-invoicing/cycle-update', params, stockConfig());
}; // 导出周期数据

export var invoicingcycleexport = function invoicingcycleexport(params) {
  return get('/v1/goods-invoicing/cycle-export', params, stockConfig({
    other: {
      responseType: 'blob'
    }
  }));
}; // 导入周期数据

export var invoicingcycleimport = function invoicingcycleimport(params, file) {
  return uploadNew('/v1/goods-invoicing/cycle-import', params, file, stockConfig());
}; // 删除周期数据

export var invoicingcycledelete = function invoicingcycledelete(params) {
  return get('/v1/goods-invoicing/cycle-delete', params, stockConfig());
}; // 仓库日报看板

export var warehousetodayreport = function warehousetodayreport(params) {
  return get('/v1/warehouse/today-report', params, stockConfig());
}; // 仓库日报看板-刷新

export var warehousetodayreportrefresh = function warehousetodayreportrefresh(params) {
  return get('/v1/warehouse/today-report-refresh', params, stockConfig());
}; // 库存报表

export var stockreportindex = function stockreportindex(params) {
  return get('/v1/stock/report/index', params, stockConfig());
}; //品牌库龄库存报表

export var stockreportbrandage = function stockreportbrandage(params) {
  return get('/v1/stock/report/brand-age', params, stockConfig());
}; //分类库存报表

export var categorystockreport = function categorystockreport(params) {
  return get('/v1/stock/report/category-stock', params, stockConfig());
}; //分类库存报表导出

export var categorystockreportexport = function categorystockreportexport(params) {
  return get('/v1/stock/report/category-stock/export', params, stockConfig());
};
export var inWarehouseQcList = function inWarehouseQcList(params) {
  return get('/v1/in-warehouse-qc', params, stockConfig());
};
export var inWarehouseQcCreate = function inWarehouseQcCreate(params) {
  return post('/v1/in-warehouse-qc', params, stockConfig());
};
export var inWarehouseQcImport = function inWarehouseQcImport(params, file) {
  return uploadNew('/v1/in-warehouse-qc/import', params, file, stockConfig());
};
export var inWarehouseQcCancel = function inWarehouseQcCancel(params) {
  return post('/v1/in-warehouse-qc/cancel', params, stockConfig());
};
export var inWarehouseQcSubmit = function inWarehouseQcSubmit(params) {
  return post('/v1/in-warehouse-qc/submit', params, stockConfig());
};
export var inWarehouseQcPrint = function inWarehouseQcPrint(params) {
  return get('/v1/in-warehouse-qc/print', params, stockConfig());
}; // 重置跟踪号获取次数

export var outboundResetRetry = function outboundResetRetry(params) {
  return post('/v1/outbound/reset-retry', params, stockConfig());
};
export var updateOutboundTracking = function updateOutboundTracking(params) {
  return post('/v1/outbound/tracking', params, stockConfig());
};
export var syncOutboundTracking = function syncOutboundTracking(params) {
  return get('/v1/outbound/tracking', params, stockConfig());
};
export var resetOutboundAllTries = function resetOutboundAllTries(params) {
  return post('/v1/outbound/reset-all-tries', params, stockConfig());
}; //导出列表

export var stockExportList = function stockExportList(params) {
  return get('/v1/export/list', params, stockConfig());
}; // 面辅料出库单

export var accoutboundlist = function accoutboundlist(params) {
  return get('/v1/acc-outbound/list', params, stockConfig());
};
export var accoutbounddetail = function accoutbounddetail(params) {
  return get('/v1/acc-outbound/detail', params, stockConfig());
};
export var accoutboundcreate = function accoutboundcreate(params) {
  return post('/v1/acc-outbound/create', params, stockConfig());
};
export var accoutboundupdate = function accoutboundupdate(params) {
  return post('/v1/acc-outbound/update', params, stockConfig());
};
export var accoutboundreview = function accoutboundreview(params) {
  return get('/v1/acc-outbound/review', params, stockConfig());
};
export var accoutboundvoucher = function accoutboundvoucher(params) {
  return post('/v1/acc-outbound/voucher', params, stockConfig());
};
export var accoutboundexport = function accoutboundexport(params) {
  return get("/v1/acc-outbound/export", params, stockConfig({
    other: {
      responseType: 'blob'
    }
  }));
};
export var accoutboundprint = function accoutboundprint(params) {
  return get('/v1/acc-outbound/print', params, stockConfig());
};
export var accoutboundlogistics = function accoutboundlogistics(params) {
  return post('/v1/acc-outbound/logistics', params, stockConfig());
};
export var accoutboundpurchasenosselect = function accoutboundpurchasenosselect(params) {
  return get('/v1/acc-outbound/purchase-nos-select', params, stockConfig());
};
export var accoutboundtabpermissions = function accoutboundtabpermissions(params) {
  return get('/v1/acc-outbound/tab-permissions', params, stockConfig());
};
export var accOutboundPurchaseEstimateSelect = function accOutboundPurchaseEstimateSelect(params) {
  return get('/v1/acc-outbound/purchase-estimate-select', params, stockConfig());
};
export var accOutboundTransfer = function accOutboundTransfer(params) {
  return post('/v1/acc-outbound/transfer', params, stockConfig());
}; // 面辅料出库退货单

export var accOutboundReturnList = function accOutboundReturnList(params) {
  return get('/v1/acc-outbound-return/list', params, stockConfig());
};
export var accOutboundReturnCreate = function accOutboundReturnCreate(params) {
  return post('/v1/acc-outbound-return/create', params, stockConfig());
};
export var accOutboundReturnReview = function accOutboundReturnReview(params) {
  return get('/v1/acc-outbound-return/review', params, stockConfig());
};
export var inventoryadddadgoods = function inventoryadddadgoods(params) {
  return post('/v1/inventory/add-bad-goods', params, stockConfig());
}; // 质检问题类型计费标准

export var qcBillingStandardList = function qcBillingStandardList(params) {
  return get('/v1/qc/problem-billing-standard/list', params, stockConfig());
};
export var qcBillingStandardCreate = function qcBillingStandardCreate(params) {
  return post('/v1/qc/problem-billing-standard/create', params, stockConfig());
};
export var qcBillingStandardUpdate = function qcBillingStandardUpdate(params) {
  return post('/v1/qc/problem-billing-standard/update', params, stockConfig());
};
export var qcBillingStandardDelete = function qcBillingStandardDelete(params) {
  return get('/v1/qc/problem-billing-standard/delete', params, stockConfig());
};
export var qcBillingStandardImport = function qcBillingStandardImport(params, file) {
  return uploadNew('/v1/qc/problem-billing-standard/import', params, file, stockConfig());
};
export var qcBillingStandardExport = function qcBillingStandardExport(params) {
  return get("/v1/qc/problem-billing-standard/export", params, stockConfig({
    other: {
      responseType: 'blob'
    }
  }));
};